module.exports = {
    is_enable: false,
    market: 'vi',
    name: null,
    icon: null,
    landing_page_key: null,
    user_permission: null,
    trigger: null,
    trigger_params: {},
    delay_time: null,
    start_time: null,
    end_time: null,
    is_enable_duration: true,
    offer_exipred_at: null,
    duration: null
}