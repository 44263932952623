<template>
    <div class="page-content">
        <page-breadcrumb title="Offers" class="mb-2" />

		<div class="d-flex justify-content-start align-items-center">
			<data-create-popup title="Create Group"
				:schema="create_schema" :default-data="defaultObject"
				:create-data-fn="createItem" @create="$refs.group_list.getList()"
			/>
		</div>
        <data-table-ssr id="group_list" ref="group_list"
            :columns="fields" :get-list-fn="getList"
            :to-edit-fn="toEditPage"
        />
    </div>
</template>
<script>
const create_schema = [
  {
    cols: 12,
    fields: [
        { label: 'Enable Offer', field: 'is_enable', input_type: 'switch'},
        { label: 'Market', field: 'market', input_type: 'language', default_value: "vi", validate: { required: true } },
        { label: 'Name', field: 'name', validate: { required: true } },
        { label: 'Offer icon URL', field: 'icon'},
        { label: 'Landing page key', field: 'landing_page_key'},
      {
        label: 'User Permission', field: 'user_permission', input_type: 'select', options: [
          { text: "All", value: 0 },
          { text: "All Free Users", value: 1 },
          { text: "Premium Not Lifetime Users", value: 2 }
        ], validate: { required: true }
      },
      {
        label: 'Trigger', field: 'trigger', input_type: 'select', options: [
          { text: "Just Create Account", value: "USER_REGISTED" },
          { text: "After [X, Y] Days Create Account", value: "USER_REGISTED_XY" }
        ], validate: { required: true }, width: "60%", class: "mr-1"
      },
        { label: 'X', field: 'trigger_params', subField: 'x', input_type: "number", width: "15%", class: "mr-1", displayCond(model) { return model.trigger === "USER_REGISTED_XY" }, validate:{ type: "number"}},
        { label: 'Y', field: 'trigger_params', subField: 'y', input_type: "number", width: "15%", displayCond(model) { return model.trigger === "USER_REGISTED_XY" }, validate:{ type: "number"}},
        { label: 'Delay Time', field: 'delay_time', validate: { required: true }},
        { label: 'Start Time', field: 'start_time', input_type: 'datetime', validate: { required: true }},
        { label: 'End Time', field: 'end_time', input_type: 'datetime', validate: { required: true }},
        { label: 'Set Expire time by Duration', field: 'is_enable_duration', input_type: 'switch'},
        { label: 'Expired At', field: 'offer_exipred_at', input_type: 'datetime', displayCond(model) { return model.is_enable_duration === false }},
        { label: 'Duration (hours)', field: 'duration', validate: { required: true }, displayCond(model) { return model.is_enable_duration === true }},
    ],
    style: "display: flex; flex-wrap: wrap"
  }
];

const fields = [
    { label: 'Market', field: 'market', input_type: 'language', validate: { required: true } },
    { label: 'Enable Offer', field: 'is_enable', input_type: 'switch'},
    { label: 'Name', field: 'name', validate: { required: true } },
    { label: 'Landing page key', field: 'landing_page_key', validate: { required: true } },
  {
    label: 'User Permission', field: 'user_permission', input_type: 'select', options: [
      { text: "All", value: 0 },
      { text: "All Free Users", value: 1 },
      { text: "Premium Not Lifetime Users", value: 2 }
    ], validate: { required: true }
  },
  {
    label: 'Trigger', field: 'trigger', input_type: 'select', options: [
      { text: "Just Create Account", value: "USER_REGISTED" },
      { text: "After [X, Y] Days Create Account", value: "USER_REGISTED_XY" }
    ], validate: { required: true }
  },
    { label: 'Start Time', field: 'start_time', input_type: 'datetime', validate: { required: true }},
    { label: 'End Time', field: 'end_time', input_type: 'datetime', validate: { required: true }},
    { label: 'Duration (hours)', field: 'duration', validate: { required: true }},
    { label: 'Delay Time (hours)', field: 'delay_time', validate: { required: true }},
];
import defaultObject from '../defaultObject.js'
import service from '../service'
export default {
    data(){
    return{
      create_schema,
      fields,
      total: 0,
      defaultObject: JSON.parse(JSON.stringify(defaultObject)),
    }
  },
  watch: {
    filter: {
      handler() {
        this.$refs.group_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page }) {
      let response_data = await service.getList({
        query: JSON.stringify(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'offer-detail', params: { id: _id } });
    },
  }
}
</script>
